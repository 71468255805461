import { Button, Typography } from '@mui/material';
import Navbar from 'reusable/Navbar/NavBar';
import { sendEmail } from 'utils/api/ContactEmail';
import { useState } from 'react';
import SuccessAlert from 'reusable/Alerts/SuccessAlert';
import ErrorAlert from 'reusable/Alerts/ErrorAlert';
import AlertList from 'reusable/Alerts/AlertList';
import { LoadingButton } from '@mui/lab';

export default function ErrorPage({ error, resetErrorBoundary }) {
  const contactFields = {
    subject: 'Bug Report',
    errorMessage: error.message,
  };

  const [alerts, setAlerts] = useState([]);
  const [reportLoading, setReportLoading] = useState(false);

  const reportError = async () => {
    setReportLoading(true);
    const emailResponse = await sendEmail(contactFields);

    if (emailResponse.ok) {
      setAlerts([
        { message: 'Error reported successfully', component: SuccessAlert },
        ...alerts,
      ]);
    } else {
      setAlerts([
        { message: 'Error could not be reported', component: ErrorAlert },
        ...alerts,
      ]);
    }
    setReportLoading(false);
  };

  const onErrorAlertClose = (index) => {
    setAlerts(alerts.filter((_, i) => i !== index));
  };
  return (
    <div>
      <Navbar />
      <div role="alert" className="p-3">
        <Typography variant="body1">
          Unfortunately an error has occurred. The error message is as follows:
        </Typography>
        <pre className="m-0 p-2">{error.message}</pre>
        <Typography variant="body1">Report this at EMAIL</Typography>
        <LoadingButton
          sx={{
            pl: 0,
          }}
          onClick={reportError}
          loading={reportLoading}
        >
          Report
        </LoadingButton>
        <Button onClick={() => navigator.clipboard.writeText(error.message)}>
          Copy Error Message
        </Button>

        <Button onClick={() => resetErrorBoundary()}>Return</Button>
        <AlertList alerts={alerts} onClose={onErrorAlertClose} />
      </div>
    </div>
  );
}
