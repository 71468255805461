/**
 * sends email to the backend to be sent to the email address used for receiving the contact form
 * @param {Event} e event object
 * @param {Object} contactFields object containing the fields of the contact form. must contain subject, others are optional and will only be used in the text of the email
 * @returns {any} response of the api call
 */
export const sendEmail = async (contactFields) => {
  const response = await fetch('/api/contact', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(contactFields),
  });

  return response;
}
