import CloseIcon from '@mui/icons-material/Close';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { useEffect, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import Backdrop from '@mui/material/Backdrop';
import { DataGrid } from '@mui/x-data-grid';
import { useSnackbar } from 'notistack';
import RotateLoader from 'react-spinners/RotateLoader';
import numToWell from 'utils/numToWell';
import { getSamples } from 'utils/api/ClientOrders';

const columnsSample = [
  {
    field: 'well',
    headerName: 'Well',
    width: 20,
    valueGetter: (params) => {
      return numToWell(params);
    },
  },
  { field: 'name', headerName: 'Sample Name', width: 300 },
  {
    field: 'primer',
    headerName: 'Primer',
    width: 300,
    renderCell: (params) => {
      if (!params.row.primer) {
        return 'premixed';
      }
      const items = params.row.primer.name.split(',|');
      return (
        <div>
          {items.map((item, index) => (
            <div key={index}>{item.trim()}</div>
          ))}
        </div>
      );
      // return params.row.primer.name;
    },
  },
  { field: 'concentration', headerName: 'Concentration', width: 120 },
  { field: 'readLength', headerName: 'Read Length', width: 120 },
  { field: 'volume', headerName: 'Volume', width: 100 },
  { field: 'sangerType', headerName: 'Type', width: 100 },
  { field: 'sangerTemplate', headerName: 'Desired Template', width: 200 },
];

const columnsFragment = [
  {
    field: 'well',
    headerName: 'Well',
    valueGetter: (params) => {
      return numToWell(params.row.well);
    },
  },
  { field: 'name', headerName: 'Sample Name', width: 250 },
  { field: 'fragmentType', headerName: 'Sample Type', width: 200 },
  { field: 'sizeStandard', headerName: 'Size standard', width: 200 },
  { field: 'fragmentDyeSet', headerName: 'Dye Set', width: 200 },
  { field: 'runModule', headerName: 'Run Module', width: 200 },
];

const SampleListView = ({
  orderCode,
  dropOffCode,
  buttonTitle = 'View Samples',
  title = 'Sample Data',
  mergeSameWellNumber = false,
  orderRerunType,
  selectFunction = false,
  selectKey,
  setBillingNotes = false,
  updateNotesFunction = false,
  columnsBilling,
}) => {
  const [open, openchange] = useState(false);

  const [sampleData, setSampleData] = useState([]); // all the data is here
  const [primerData, setPrimerData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [orderType, setOrderType] = useState('sanger');

  const { enqueueSnackbar } = useSnackbar();
  const [selectFunctionLoading, setSelectFunctionLoading] = useState(false);
  const checkbox = selectFunction ? true : false;

  const handleSelectionModelChange = (newSelectionModel) => {
    const selectedData = sampleData.filter((row) =>
      newSelectionModel.includes(row.id)
    );
    setSelectedRows(selectedData);
  };

  const handleButtonClick = async () => {
    if (selectFunction) {
      setSelectFunctionLoading(true);
      try {
        await selectFunction(selectedRows);
        enqueueSnackbar('Operation successful!', { variant: 'success' }); // possibly update messages
      } catch (error) {
        console.error('Error executing selectFunction:', error);
        enqueueSnackbar('An error occurred. Please try again.', {
          variant: 'error',
        });
      } finally {
        setSelectFunctionLoading(false);
      }
    }
    if (updateNotesFunction) {
      try {
        await updateNotesFunction();
        enqueueSnackbar('Successfully updated notes!', { variant: 'success' });
      } catch (error) {
        console.error('Error executing notes updates:', error);
        enqueueSnackbar(
          'An error occurred with updating notes. Please try again.',
          {
            variant: 'error',
          }
        );
      }
    }
  };

  const handleOpenPopup = () => {
    openchange(true);
  };

  const handleClosePopup = () => {
    openchange(false);
  };

  async function doSetSelectedRows(sampleDataInput) {
    if (selectKey) {
      var newSelectedRows = sampleDataInput.filter(
        (row) => row[selectKey] === true
      );
      setSelectedRows(newSelectedRows);
    }
  }

  async function getSangerData() {
    setIsLoading(true);
    setSelectedRows([]);
    const res = await getSamples(orderCode, dropOffCode);
    if (res.status === 200) {
      console.log(res.data);
      let resSampleData = await addIncrementalId(res.data.samples);
      resSampleData.sort((a, b) => a.well - b.well);
      let indexesToBeDeleted = [];
      let left = 0;
      if (mergeSameWellNumber) {
        for (let right = 1; right < resSampleData.length; right++) {
          if (
            resSampleData[left].primer &&
            resSampleData[right].primer &&
            resSampleData[left].well === resSampleData[right].well
          ) {
            resSampleData[left].primer.name +=
              ',|' + resSampleData[right].primer.name;
            indexesToBeDeleted.push(right);
          } else {
            left = right;
          }
        }
        let updatedResSampleData = resSampleData.filter(
          (_, index) => !indexesToBeDeleted.includes(index)
        );
        resSampleData = updatedResSampleData;
      }
      if (setBillingNotes) {
        resSampleData.forEach((sample) => {
          setBillingNotes((prevState) => ({
            ...prevState,
            [sample._id]: sample.billingNotes,
          }));
        });
      }
      setSampleData(resSampleData);
      doSetSelectedRows(resSampleData);
      setPrimerData(res.data.primers);
      setOrderType(res.data.orderType);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (open === true) {
      getSangerData();
    }
  }, [open]);

  const addIncrementalId = (data) => {
    return data.map((item, index) => ({
      ...item,
      id: index,
    }));
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <Button onClick={handleOpenPopup} color="primary" variant="contained">
        {buttonTitle}
      </Button>
      <Dialog open={open} onClose={handleClosePopup} fullWidth maxWidth="xl">
        <Backdrop sx={{ color: '#fff', zIndex: 3 }} open={isLoading}>
          <RotateLoader />
        </Backdrop>
        <DialogTitle>
          {orderRerunType === 'internal'
            ? `${orderCode}_IRR`
            : orderRerunType === 'external'
            ? `${orderCode}_ERR`
            : orderCode}{' '}
          {/* TODO: Change to use getOrderCode function */}
          {title}
          <IconButton onClick={handleClosePopup} style={{ float: 'right' }}>
            <CloseIcon color="primary"></CloseIcon>
          </IconButton>
        </DialogTitle>
        <DialogContent style={{ flexGrow: 1 }}>
          <DataGrid
            style={{ height: 500 }}
            checkboxSelection={checkbox}
            rows={sampleData}
            columns={
              columnsBilling ??
              (orderType === 'sanger' ? columnsSample : columnsFragment)
            }
            pageSize={96}
            selectionModel={selectedRows.map((row) => row.id)}
            onSelectionModelChange={handleSelectionModelChange}
            getRowHeight={() => 'auto'}
          />
          {selectFunction && (
            <div style={{ textAlign: 'center' }}>
              <LoadingButton
                size="large"
                variant="outlined"
                onClick={handleButtonClick}
                disabled={isLoading || selectFunctionLoading}
                loading={selectFunctionLoading}
                loadingIndicator={
                  <RotateLoader
                    cssOverride={{ left: '100%' }}
                    size={7}
                    margin={-15}
                  />
                }
              >
                Submit
              </LoadingButton>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SampleListView;
