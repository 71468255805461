import { Alert } from '@mui/material';

// for 'announcement' | 'critical' | 'maintanence' respectively
import CampaignIcon from '@mui/icons-material/Campaign';
import WarningIcon from '@mui/icons-material/Warning';
import EngineeringIcon from '@mui/icons-material/Engineering';
import { useState } from 'react';

const Message = ({ message }) => {
  const [messageHidden, setMessageHidden] = useState(false);

  const { MESSAGE_TYPE, MESSAGE_TEXT, MESSAGE_LINK } = message;

  const severity =
    MESSAGE_TYPE === 'announcement'
      ? 'success'
      : MESSAGE_TYPE === 'critical'
      ? 'error'
      : MESSAGE_TYPE === 'maintanence'
      ? 'warning'
      : 'info';

  const handleClick = () => {
    if (MESSAGE_LINK) {
      window.location.href = MESSAGE_LINK;
    }
  };

  return (
    // types: announcement - blue, critical - red, maintenance - dark yellow / orange
    !messageHidden && (
      <Alert
        icon={
          MESSAGE_TYPE === 'announcement' ? (
            <CampaignIcon />
          ) : MESSAGE_TYPE === 'critical' ? (
            <WarningIcon />
          ) : (
            <EngineeringIcon />
          )
        }
        variant="filled"
        severity={severity}
        onClick={handleClick}
        onClose={(e) => {
          e.stopPropagation();
          setMessageHidden(true);
        }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          borderRadius: 0,
          cursor: 'pointer',
        }}
      >
        {MESSAGE_TEXT}
      </Alert>
    )
  );
};

export default Message;
