import './ContactUs.css';
import Locations from 'reusable/Locations/Locations';
import { useWindowSize } from 'hooks/useWindowSize';
import FormInput from 'reusable/FormInput/FormInput';
import { useState, useRef } from 'react';
import { sendEmail } from 'utils/api/ContactEmail';
import Backdrop from '@mui/material/Backdrop';
import CheckIcon from '@mui/icons-material/Check';
import Alert from '@mui/material/Alert';

export default function ContactUs() {
  const [width] = useWindowSize();
  const [formResponse, setFormResponse] = useState(null);

  const [contactFields, setContactFields] = useState({
    name: '',
    billingGroup: '',
    subject: '',
    email: '',
    message: '',
  });
  const formRef = useRef();

  return (
    <div className="contact__container">
      <Backdrop
        sx={{ color: '#fff', zIndex: 1 }}
        open={formResponse !== null}
        onClick={() => setFormResponse(null)}
      >
        {formResponse && (
          <Alert
            onClose={() => {
              setFormResponse(null);
            }}
            icon={formResponse.ok ? <CheckIcon fontSize="inherit" /> : <></>}
            severity={formResponse.ok ? 'success' : 'error'}
          >
            {formResponse.statusText}
          </Alert>
        )}
      </Backdrop>
      <div className="page__title">Contact Us</div>
      <div className="contact__subcontainer">
        <h2 className="contact__subheading">Office Hours</h2>
        <h2 className="contact__subheading">Fridays 12-1 PM</h2>
        <a href="https://outlook.office365.com/owa/calendar/molevolgt1@gtvault.onmicrosoft.com/bookings/">
          <h2 className="contact__subheading">Book Your Consultation</h2>
        </a>{' '}
      </div>
      <div>
        <h2 className="contact__subheading">
          Office hours can be used for consultations, along with any questions
          about our services
        </h2>
      </div>
      <Locations screenWidth={width} />
      <form
        className="contact__form"
        id="contactForm"
        ref={formRef}
        onSubmit={async (e) => {
          e.preventDefault();
          const emailResponse = await sendEmail(contactFields);

          setFormResponse(emailResponse);

          if (emailResponse.ok) {
            formRef.current.reset();
          }
        }}
      >
        <div className="contact__title">Contact Form</div>
        <FormInput
          label="Name (First and Last):"
          id="contactName"
          type="text"
          onChange={(e) => {
            setContactFields({ ...contactFields, name: e.target.value });
          }}
        ></FormInput>
        <FormInput
          label="Billing Group Name:"
          id="contactBillingGroup"
          type="text"
          onChange={(e) => {
            setContactFields({
              ...contactFields,
              billingGroup: e.target.value,
            });
          }}
        ></FormInput>
        <FormInput
          label="Subject:"
          id="contactSubject"
          type="text"
          onChange={(e) => {
            setContactFields({ ...contactFields, subject: e.target.value });
          }}
        ></FormInput>
        <FormInput
          label="Email:"
          id="contactEmail"
          type="email"
          onChange={(e) => {
            setContactFields({ ...contactFields, email: e.target.value });
          }}
        ></FormInput>
        <FormInput
          label="Message:"
          id="contactMessage"
          type="text"
          onChange={(e) => {
            setContactFields({ ...contactFields, message: e.target.value });
          }}
        ></FormInput>

        <div className="contact_submit-container">
          <button
            className="contact_submit-button"
            type="submit"
            form="contactForm"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
