import { useContext, useEffect, useState } from 'react';
import Message from './Message';
import { Outlet } from 'react-router';
import AuthContext from 'utils/auth/auth';
import axios from 'axios';

const WithMessage = () => {
  const user = useContext(AuthContext);
  const [message, setMessage] = useState('');

  useEffect(() => {
    (async () => {
      const message = await axios.get(`/api/message`);
      console.log(message);
      setMessage(message.data);
    })();
  }, []);

  // as discussed, currently there will be no distinction between employee and admin for message component
  const isEmployee = user === undefined ? false : user.employee || user.admin;
  // if not employee/admin, user will be treated as client. no distinction btwn logged in or out currently
  const isClient = !isEmployee;

  return (
    <>
      {((isEmployee && message.MESSAGE_AUDIENCE === 'employee') ||
        message.MESSAGE_AUDIENCE === 'client') && <Message message={message} />}
      <Outlet />
    </>
  );
};

export default WithMessage;
